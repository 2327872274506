/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { formatDollar } from "@onehq/helpers";
import { Table, TableProps } from "@onehq/anton";
import { CollectionListFieldsFragment } from "../../generated/graphql";

import { BaseTableProps } from "../../types";
import { Link } from "@onehq/framework";
import { COLLECTIONS_PATH } from "../../constants/collectionConstants";
import { formatDate } from "../../utils";

type CollectionRow = CollectionListFieldsFragment;

export type CollectionTableProps = BaseTableProps<CollectionRow>;

export const CollectionTableColumns = [
  {
    id: "collectionType",
    header: "Type",
    accessorKey: "collectionType"
  },
  {
    id: "amount",
    header: "Amount",
    accessorKey: "amount",
    type: "number"
  },
  {
    id: "fee",
    header: "Fee",
    accessorKey: "fee",
    type: "number"
  },
  {
    id: "collectionDate",
    header: "Date",
    accessorKey: "collectionDate"
  }
];

export const collectionTableDataFormatter = (data?: Array<CollectionRow>) => [
  ...(data?.map((item: CollectionRow) => {
    const { id, collectionType, amount, fee, collectionDate } = item;
    const type = collectionType;
    const path = `/${COLLECTIONS_PATH}/${id}/overview`;
    const date = collectionDate ? formatDate(collectionDate) : "";

    return {
      collectionType: id ? <Link to={path}>{type}</Link> : type,
      amount: formatDollar(amount, 2),
      fee: fee ? formatDollar(fee, 2) : "-",
      collectionDate: date.toString().split(" ")[0]
    };
  }) || [])
];

const CollectionTable = ({
  data = [],
  summary,
  ...props
}: CollectionTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  const columns: TableProps["columns"] = useMemo(() => {
    if (!summary) return CollectionTableColumns;
    return CollectionTableColumns.map((column: any) => {
      const footer = summary[column.accessorKey];
      if (footer) column.footer = footer;
      return column;
    });
  }, [CollectionTableColumns, summary]);

  return (
    <Table
      columns={columns}
      data={collectionTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
      disableSort
    />
  );
};

export default CollectionTable;
