// react modules
import React from "react";

// third-party modules
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";
import moment, { Moment } from "moment";
import _ from "lodash";

// app modules
import ListPage from "../../components/pages/ListPage";
import {
  useGetProjectQuery,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  ProjectFieldsFragment,
  SendingType,
  MediaPosition,
  DeliveryType,
  CampaignType,
  ListFilterFieldsFragment
} from "../../generated/graphql";
import sections, { projectFormOmitValues as formOmitValues } from "./sections";
import ProjectAnchor from "./ProjectAnchor";
import ProjectShow from "./ProjectShow";
import {
  PROJECTS_PATH,
  PROJECT_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import DataOverview from "../../components/pages/DataOverview";
import {
  formatFilters,
  ListFilterValue,
  normalizeFilters
} from "../../components/ListFilter/utils";
import {
  formatTime,
  htmlToUnicode,
  normalizeDate,
  ProjectSection,
  TimeZoneInput,
  unicodeToHtml
} from "../../components/Project/utils";
import { ActionsButton } from "../../components/Actions";
import {
  loadPricesInitialValues,
  loadPricesNormalizedValues
} from "../../components/Price/PriceSection";

// a row in the table of the project campaigns section
interface Row {
  [key: string]: any;
}

export function projectInitialValues(project?: ProjectFieldsFragment) {
  // project is type of the query data response
  // getting useful data to generate initial values
  const {
    list,
    listFilters = [],
    manager,
    client,
    timeZone,
    startAt,
    endAt,
    projectTeams,
    projectTextersAttributes,
    projectPhones
  } = project || {};
  const listFiltersAttributes = formatFilters(listFilters || []);

  const initialValues = {
    media: {
      name: project?.mediaFilename,
      path:
        (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") +
        project?.mediaUrl,
      type: project?.mediaFiletype,
      size: ""
    },
    listId: list
      ? {
          label: list.name,
          value: list.id
        }
      : undefined,
    managerId: manager?.name
      ? {
          label: manager.name,
          value: manager.id
        }
      : undefined,
    clientId: client
      ? {
          label: client.name,
          value: client.id
        }
      : undefined,
    timeZoneData: timeZone && {
      label: timeZone.name,
      value: {
        id: timeZone?.id,
        pgTimeZoneName: timeZone?.pgTimezoneName
      }
    },
    startAtDate: startAt ? moment(startAt).format("YYYY-MM-DD") : undefined,
    startAtTime: formatTime(startAt, timeZone),
    endAtTime: formatTime(endAt, timeZone),
    projectTeamsAttributes: projectTeams?.map(pt => pt.teamId) || [],
    projectTextersAttributes,
    projectPhones: [...(projectPhones || [])],
    sendingType: project?.sendingType || SendingType.MostCommonAreaCode,
    // this is calculated, all project campaigns have the same
    campaignType: project?.campaignType || CampaignType.Registered,
    mediaPosition: project?.mediaPosition || MediaPosition.NoPreference,
    deliveryType: project?.deliveryType || DeliveryType.External,
    listFilters,
    listFiltersAttributes,
    body: project?.message ? unicodeToHtml(project?.message) : undefined,
    prices: loadPricesInitialValues(project?.prices || [])
  };
  // this previous is all the data of the initial values
  // this helps compare the normalize updated values with the previous values
  const previous = { ...project };
  return { ...initialValues, previous };
}

export function normalizeProject(project: any) {
  // project is type of the form fields
  // getting useful data to normalize
  const {
    id,
    listFilters,
    listFiltersAttributes,
    media,
    projectTeams,
    projectTeamsAttributes,
    projectTexters,
    projectTextersAttributes: pta,
    projectPhonesAttributes, // table data (always have one and only one child)
    projectPhones,
    prices,
    previous // the values before the change
  } = project;
  // message
  project.message = htmlToUnicode((project.body as string) || "");

  const projectTextersAttributes = pta?.map(pt => ({ ...pt, id: pt.texterId }));

  // sets startAt and endAt
  const timeZoneData = project.timeZoneData as TimeZoneInput | undefined;
  const startDate = project.startAtDate as string | undefined;
  const startTime = project.startAtTime as Moment | undefined;
  const endTime = project.endAtTime as Moment | undefined;
  const startAt = normalizeDate(startDate, startTime, timeZoneData);
  const endAt = normalizeDate(startDate, endTime, timeZoneData);

  // sets media data
  const mediaDestroy = !media;
  const mediaInput = media && media.size !== "" ? media : undefined;

  // reset media position if remove media
  if (previous.mediaFilename && mediaDestroy) {
    project.mediaPosition = MediaPosition.NoPreference;
  }

  // sets teams and texters
  let newProjectTeams: any[] = [];
  let newProjectTexters: any[] = [];
  const texters = projectTextersAttributes?.filter(t => t.id || t.quota);

  if (id) {
    // if is edition
    /** ******** Generating project teams attributes **********/
    let currentProjectTeams: any[] = projectTeams?.length
      ? [...projectTeams]
      : [];
    projectTeamsAttributes?.forEach((ptTeamId: string) => {
      const indexAux = currentProjectTeams?.findIndex(
        projectTeam => ptTeamId === projectTeam.teamId
      );
      // avoiding the case indexAux = 0 is false
      if ((indexAux || indexAux === 0) && indexAux >= 0) {
        // removing from current project teams (these would be deleted later, we dont want that)
        currentProjectTeams.splice(indexAux, 1);
      } else {
        // this is a new projectTeam relation to create
        newProjectTeams.push({
          teamId: ptTeamId
        });
      }
    });
    // remaining existing projectTeams should be deleted
    currentProjectTeams = currentProjectTeams.map(pt => {
      return {
        id: pt.id,
        teamId: pt.teamId,
        _destroy: true
      };
    });
    // attach new project teams with the ones to delete
    newProjectTeams = [...newProjectTeams, ...currentProjectTeams];
    /** ******************/

    /** ******** Generating project texters attributes **********/
    let currentProjectTexters: any[] = projectTexters?.length
      ? [...projectTexters]
      : [];
    const editedProjectTexters: any[] = [];
    texters?.forEach(pt => {
      const indexAux = currentProjectTexters?.findIndex(
        projectTexter => pt.userId === projectTexter.userId
      );
      // avoiding the case indexAux = 0 is false
      if ((indexAux || indexAux === 0) && indexAux >= 0) {
        // this is a projectTexter relation to edit
        editedProjectTexters.push({
          id: pt.id,
          userId: pt.userId,
          quota: pt.quota,
          _destroy: !pt.quota
        });

        // removing from current project texters (these would be deleted later) we dont want that
        currentProjectTexters.splice(indexAux, 1);
      } else {
        // this is a new projectTexter relation to create
        newProjectTexters.push({
          userId: pt.userId,
          quota: pt.quota
        });
      }
    });

    // remaining existing projectTexters should be deleted
    currentProjectTexters = currentProjectTexters.reduce(function (
      filtered,
      pt
    ) {
      filtered.push({
        id: pt.id,
        quota: pt.quota,
        userId: pt.userId,
        _destroy: true
      });
      return filtered;
    },
    []);
    // attach new project texters with the ones to delete
    newProjectTexters = [
      ...newProjectTexters,
      ...editedProjectTexters,
      ...currentProjectTexters
    ];
    /** ******************/
  } else {
    /** ******** All project texter relations will be new, setting projectTeamsAttributes directly from form then **********/
    newProjectTeams = projectTeamsAttributes?.map((ptTeamId: string) => {
      // changed name from projectTeamsAttributes
      return {
        teamId: ptTeamId
      };
    });
    /** ******************/
    newProjectTexters =
      texters?.map(({ quota, userId }) => ({
        quota,
        userId
      })) || [];
  }

  const filters: ListFilterValue[] = listFiltersAttributes;
  const previousFilters: ListFilterFieldsFragment[] = listFilters;
  project.listFilters = normalizeFilters(filters, previousFilters);

  const isUnregistered = project.campaignType === CampaignType.Unregistered;

  const tableData = projectPhonesAttributes || [];

  const hasRowMissingData = tableData.some(
    row => row.campaignId && !row.phoneData && !isUnregistered
  );

  const hasRowCampaignChanged = tableData.some(row => {
    const previousCampaignId = projectPhones.find(
      projectPhone =>
        parseInt(row.areaCode as string, 10) === projectPhone.areaCode
    )?.campaignId;
    return (
      row.campaignId &&
      previousCampaignId &&
      row.campaignId !== previousCampaignId &&
      row.phoneData &&
      row.phoneData.campaignId !== row.campaignId
    );
  });

  const isIncompleteSendingFrom = hasRowMissingData || hasRowCampaignChanged;

  // if "sending from" section has incomplete data and nothing else has changed
  // in the project form, ignore the submit
  if (
    isIncompleteSendingFrom &&
    !somethingElseChanged(project, "Sending From")
  ) {
    return { ...project, _omitSubmit: true };
  }

  const newProjectPhones: any[] = [];

  tableData.forEach(({ areaCode: ac, campaignId, phoneData }: Row) => {
    const areaCode = parseInt(ac as string, 10);
    const phoneId = phoneData?.id;
    const projectPhone = projectPhones.find(
      projectPhone => projectPhone.areaCode === areaCode
    );
    // is creating?
    if (!projectPhone && (campaignId || isUnregistered) && phoneId) {
      newProjectPhones.push({
        id: null,
        campaignId: isUnregistered ? phoneData?.campaignId : campaignId,
        areaCode,
        phoneId
      });
      return;
    }
    // is updating?
    if (projectPhone && (campaignId || isUnregistered) && phoneId) {
      newProjectPhones.push({
        id: projectPhone.id,
        campaignId: isUnregistered ? phoneData?.campaignId : campaignId,
        areaCode,
        phoneId
      });
      return;
    }
    // is removing?
    if (projectPhone && (!campaignId || (isUnregistered && !phoneId))) {
      newProjectPhones.push({
        id: projectPhone.id,
        _destroy: true
      });
      return;
    }
  });

  return {
    ..._.omit(project, formOmitValues),
    startAt: startAt?.toISOString() || null,
    endAt: endAt?.toISOString() || null,
    timeZoneId: timeZoneData?.id || null,
    mediaDestroy,
    mediaInput,
    projectTexters: newProjectTexters,
    projectTeams: newProjectTeams,
    projectPhones: newProjectPhones,
    prices: loadPricesNormalizedValues(prices)
  };
}

// checks if some section changed that was not the section provided
function somethingElseChanged(project: any, section: ProjectSection) {
  const previous = project.previous;
  if (section !== "Basic Info") {
    const changed = ["name", "projectType", "projectStatus", "note"].some(
      f => project[f] !== previous[f]
    );
    if (changed) return true;
    if (project.clientId !== previous.client?.id) return true;
    if (project.listId !== previous.list?.id) return true;
    if (project.managerId !== previous.manager?.id) return true;
  }
  if (section !== "Message") {
    if (project.message !== previous.message) return true;
    // TODO: media check
  }
  if (section !== "Sending From") {
    const changed = ["campaignType", "sendingType", "mediaPosition"].some(
      f => project[f] !== previous[f]
    );
    if (changed) return true;
    if (project.projectPhones?.length !== previous.projectPhones?.length)
      return true;
  }
  if (section !== "Sending Schedule") {
    const changed = ["startAt", "endAt", "endAt"].some(
      f => project[f] !== previous[f]
    );
    if (changed) return true;
    if (project.timeZoneData?.id !== previous.timeZone?.id) return true;
  }
  if (section !== "Texters") {
    if (project.deliveryType !== previous.deliveryType) return true;
    // pta(s) = projectTeamsAttribute(s)
    const projectPtas = project.projectTeamsAttributes || [];
    if (projectPtas.length !== previous.projectTeams.length) return true;
    const previousQuota = (previous.projectTexters || []).reduce(
      (total, current) => total + current.quota,
      0
    );
    const projectQuota = (project.projectTextersAttributes || []).reduce(
      (total, current) => total + current.quota,
      0
    );
    if (projectQuota !== previousQuota) return true;
  }
  return false;
}

const ProjectsRoute = new ResourceRoute({
  name: "Project",
  path: PROJECTS_PATH,
  customNewPath: "/projects/stepper",
  IndexComponent: () => <ListPage variant="Projects" />, // list of projects
  AnchorComponent: ProjectAnchor,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Project",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Teams",
      path: "teams",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.TEAMS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.TEAMS} />
      )
    }),
    new NestedResourceRoute({
      name: "Texters",
      path: "project_texters",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.TEXTERS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.TEXTERS} />
      )
    }),
    new NestedResourceRoute({
      name: "Texts",
      path: "texts",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.TEXTS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Test Texts",
      path: "test_texts",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.TEST_TEXTS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.TEST_TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Seed Texts",
      path: "seed_texts",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.SEED_TEXTS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.SEED_TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Insights",
      path: "insights",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.TEXTS_INSIGHTS}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.TEXTS_INSIGHTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Duplicate Phones",
      path: "duplicate_phones",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.DUPLICATE_PHONES}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.DUPLICATE_PHONES} />
      )
    }),
    new NestedResourceRoute({
      name: "Invalid Phones",
      path: "invalid_phones",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.INVALID_PHONES}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.INVALID_PHONES} />
      )
    }),
    new NestedResourceRoute({
      name: "Opt Out Phones",
      path: "opt_out_phones",
      baseRoute: "Project",
      actions: (
        <ActionsButton
          baseResource={"Project"}
          variant={VIEW_LINKS.OPT_OUT_PHONES}
          showExportButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.OPT_OUT_PHONES} />
      )
    }),
    new NestedResourceRoute({
      name: "List Filters",
      path: "list-filters",
      baseRoute: "Project",
      IndexComponent: ({ id }: { id: string }) => (
        <ProjectShow id={id} activeLinkId={VIEW_LINKS.LIST_FILTERS} />
      )
    })
  ],
  formProps: {
    scrollable: true,
    sections,
    // @ts-ignore
    query: [useGetProjectQuery, "project"],
    // @ts-ignore
    update: [useUpdateProjectMutation, "updateProject"],
    // @ts-ignore
    create: [useCreateProjectMutation, "createProject"],
    // @ts-ignore
    useInitialValues: projectInitialValues,
    normalize: normalizeProject
  }
});

export { ProjectsRoute };
