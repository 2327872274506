import { Fieldset, TextField, ColorPickerField } from "@onehq/anton";
import React from "react";

export default function ClientOptIn() {
  return (
    <Fieldset>
      <TextField name="slug" label="Slug" />
      <TextField name="email" label="Email" />
      <TextField name="phone" label="Phone" />
      <TextField name="website" label="Website" />
      <ColorPickerField name="color" label="Select a color" />
      <TextField name="slogan" label="Slogan" />
      <TextField name="state" label="State" />
    </Fieldset>
  );
}
