import { Pagination } from "@onehq/anton";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { GrowlAlertDispatch, useDispatchGrowlContext } from "@onehq/framework";
import { addErrorAlert } from "../../utils";
import {
  useAuditReportQuery,
  useBillingReportQuery,
  useGetCollectionsListQuery
} from "../../generated/graphql";
import AuditTable from "./auditReportTable";
import BillingTable from "../billingReport/billingReportTable";
import CollectionTable from "../../components/Collection/CollectionTable";
import { useResourceTabsProvider } from "../../providers/resource_tabs_provider";
import DatesRangeForm from "./datesRangeForm";

const PAGE_SIZE = 15;

const PaginationContainer = styled.div<{ hide: boolean }>`
  margin-top: 18px;
  display: ${props => (props.hide ? "none" : "flex")};
  justify-content: center;
`;

const variants = {
  auditReport: {
    query: useAuditReportQuery,
    Table: AuditTable
  },
  billingReport: {
    query: useBillingReportQuery,
    Table: BillingTable
  },
  collections: {
    query: useGetCollectionsListQuery,
    Table: CollectionTable
  }
};

const defaultNormalize = (startDate: string, endDate: string) => {
  return { startDate, endDate };
};

interface ReportFormProps {
  variant: "auditReport" | "billingReport" | "collections";
  normalize?: (startDate: string, endDate: string) => Object;
  onSubmit?: (startDate: string, endDate: string) => void;
  summary?: Object;
}

const ReportForm = ({
  variant,
  normalize = defaultNormalize,
  onSubmit,
  summary
}: ReportFormProps) => {
  const { setValue: setExportCsvVariables } = useResourceTabsProvider();
  const { query, Table } = variants[variant];

  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
  const [page, setPage] = useState(1);

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const { data, loading, refetch } = query({
    fetchPolicy: "cache-and-network",
    variables: normalize(today, today) as any
  });
  const rows = useMemo(() => {
    return data?.[variant].nodes?.filter(n => n !== null) || [];
  }, [data, variant]);
  const paginatedRows = useMemo(() => {
    const start = (page - 1) * PAGE_SIZE;
    return rows.slice(start, start + PAGE_SIZE);
  }, [rows, page]);
  const totalPages = useMemo(() => {
    return Math.ceil(rows.length / PAGE_SIZE);
  }, [rows]);

  const areDatesValid = useMemo(() => {
    return (startDate: string, endDate: string) => {
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          const message = "Start date must not be greater than the end date";
          addErrorAlert(alert, "Invalid date", message);
          return false;
        }
      }
      return true;
    };
  }, [alert]);

  const onFiltersSubmit = ({ startDate = "", endDate = "" }) => {
    if (areDatesValid(startDate, endDate)) {
      setExportCsvVariables(normalize(startDate, endDate));
      setPage(1);
      if (onSubmit) onSubmit(startDate, endDate);
      void refetch(normalize(startDate, endDate));
    }
    return Promise.resolve();
  };

  return (
    <>
      <DatesRangeForm defaultDate={today} onSubmit={onFiltersSubmit} />
      <Table data={paginatedRows} loading={loading} summary={summary} />
      <PaginationContainer hide={totalPages < 2}>
        <Pagination
          currentPage={page}
          onPageClick={page => setPage(page)}
          totalPages={totalPages}
        />
      </PaginationContainer>
    </>
  );
};

export default ReportForm;
