/* eslint-disable react-hooks/exhaustive-deps */
import { LinkId } from "@onehq/anton";
import React, { useEffect, useMemo, useState } from "react";
import {
  ADD,
  GrowlAlertDispatch,
  List,
  useDispatchGrowlContext
} from "@onehq/framework";

import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  useGetProjectTextersListLazyQuery,
  useGetTeamsListLazyQuery,
  useGetTextsListLazyQuery,
  TextQueryFilterFields,
  ProjectTeamQueryFilterFields,
  FilterOperation,
  GetTextsListQueryVariables,
  GetProjectTextersListQueryVariables,
  GetTeamsListQueryVariables,
  useTextInsightsLazyQuery,
  TextInsightsQueryVariables,
  TextStatus,
  useGetOptOutTextsListLazyQuery,
  useGetProjectQuery,
  ProjectFieldsFragment,
  BaseTextFieldsFragment,
  LoadSendProjectOption,
  LoadSendProjectDocument,
  useGetListSummaryLazyQuery,
  GetListSummaryQueryVariables,
  GetOptOutTextsListQueryVariables,
  TextQueryFilterInput,
  ListFilterQueryFilterFields,
  GetListFiltersListQueryVariables,
  useGetProjectTeamsListLazyQuery,
  useGetListFiltersListLazyQuery
} from "../../../generated/graphql";
import {
  PROJECT_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  projectTabFilters
} from "../../../constants/projectConstants";
import ToPhoneTabFilter from "../../../utils/ToPhoneTabFilter";
import DataOverview from "../../../components/pages/DataOverview";
import {
  TeamTableColumns,
  teamTableDataFormatter
} from "../../../components/Team/TeamTable";
import {
  ProjectTexterTableColumns,
  projectTexterDataFormatter
} from "../../../components/ProjectTexter/ProjectTexterTable";
import { addErrorAlert, PAGE_SIZE } from "../../../utils";
import {
  TextInsightsTableColumns,
  textInsightsDataFormatter
} from "../../../components/Text/TextInsightsTable";
import ProjectTestTexts from "../ProjectAnchor/ProjectTestTexts";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import {
  getRowTableColumns,
  rowTableDataFormatter
} from "../../../components/Row/RowTable";
import TextTypeSelect from "./TextTypeSelect";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";
import {
  FiltersTableColumns,
  listFilterTableDataFormatter
} from "../../../components/ListFilter/ListFilterTable";
import { message } from "../../../components/ListFilter/utils";

const formatTextType = (textType?: string) => {
  if (textType === TextStatus.Received) return "ReceivedNotOptedOut";
  if (!textType) return TextStatus.Delivered;
  return textType.replace(" ", "");
};

interface ProjectShowProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ProjectShow = ({ id, ...props }: ProjectShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();
  const location = useLocation();
  const textType = (location.state as { textType?: string })?.textType;

  const [selectedToPhone, setSelectedToPhone] = useState<any>(null);
  const [tabFilters, setTabFilters] = useState<TextQueryFilterInput[]>([]);

  const [selectedTextType, setSelectedTextType] = useState<string>(
    formatTextType(textType)
  );

  useEffect(() => {
    setSelectedTextType(formatTextType(textType));
  }, [textType]);

  // getting texts
  const [, { data: textsData, loading: loadingTexts, refetch: refetchTexts }] =
    useGetTextsListLazyQuery({
      variables: projectTabFilters({
        id,
        selectedTextType,
        tabFilters,
        selectedTab: props.activeLinkId as string
      }).variables as GetTextsListQueryVariables,
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response?.texts?.totalCount);
      }
    });

  const handleTextsRefetch = (options: GetTextsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      tabFilters,
      ...options
    }));
    void refetchTexts(options);
  };

  const [
    ,
    {
      data: optOutTextsData,
      loading: loadingOptOutTexts,
      refetch: refetchOptOutTexts
    }
  ] = useGetOptOutTextsListLazyQuery({
    variables: projectTabFilters({
      id,
      selectedTextType,
      selectedTab: props.activeLinkId as string
    }).variables as GetOptOutTextsListQueryVariables,
    onCompleted: response => {
      props.setTotalPages &&
        props.setTotalPages(response?.optOutTexts?.totalCount);
    }
  });

  const handleOptOutTextsRefetch = (
    options: GetOptOutTextsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchOptOutTexts(options);
  };

  const [
    ,
    { data: rowsData, loading: loadingRows, refetch: refetchListSummary }
  ] = useGetListSummaryLazyQuery({
    variables: projectTabFilters({
      id,
      selectedTab: props.activeLinkId as string
    }).variables as GetListSummaryQueryVariables,
    onCompleted: response => {
      props.setTotalPages &&
        props.setTotalPages(response?.listSummary?.totalCount);
    }
  });

  const handleListSummaryRefetch = (options: GetListSummaryQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchListSummary(options);
  };

  const [sendTextQuery] = useLazyQuery(LoadSendProjectDocument);

  // getting project texters
  const [
    ,
    {
      data: projectTextersData,
      loading: loadingProjectTexters,
      refetch: refetchProjectTexters
    }
  ] = useGetProjectTextersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: projectTabFilters({
      id,
      selectedTab: LIST_VIEW.TEXTERS
    }).variables as GetProjectTextersListQueryVariables,
    onCompleted: response => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages &&
        props.setTotalPages(response.projectTexters.totalCount);
    }
  });

  const handleProjectTextersRefetch = (
    options: GetProjectTextersListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchProjectTexters(options);
  };

  // getting text insights
  const [
    ,
    {
      data: textInsightsData,
      loading: loadingTextInsights,
      refetch: refetchTextInsights
    }
  ] = useTextInsightsLazyQuery({
    fetchPolicy: "no-cache",
    variables: projectTabFilters({
      id,
      selectedTab: LIST_VIEW.TEXTS_INSIGHTS
    }).variables as TextInsightsQueryVariables
  });

  const handleTextInsightsRefetch = (options: TextInsightsQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchTextInsights(options);
  };

  // getting project teams related to this project
  const [
    ,
    {
      data: projectTeamsData,
      loading: projectTeamsloading,
      error: projectTeamsError,
      refetch: refetchProjectTeams
    }
  ] = useGetProjectTeamsListLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: ProjectTeamQueryFilterFields.ProjectId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const hasValues =
    !!projectTeamsData &&
    !projectTeamsloading &&
    !projectTeamsError &&
    !!projectTeamsData.projectTeams?.nodes;
  const teamsInProject = hasValues
    ? projectTeamsData.projectTeams?.nodes?.map(pt => pt?.teamId || "")
    : [];

  // getting teams to show, using the projectTeams list to make the filters
  const [, { data: teamsData, loading: loadingTeams, refetch: refetchTeams }] =
    useGetTeamsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: projectTabFilters({
        id,
        teamsInProject,
        selectedTab: LIST_VIEW.TEAMS
      }).variables as GetTeamsListQueryVariables,
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response.teams.totalCount);
      }
    });

  const handleTeamsRefetch = (options: GetTeamsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchTeams(options);
  };

  useEffect(() => {
    const toPhoneIdInTexts =
      selectedToPhone?.map(
        (object: { label: string; value: string }) => object?.value
      ) || [];
    const FilterQueryVariables2 =
      toPhoneIdInTexts?.length > 0
        ? [
            {
              field: TextQueryFilterFields.ToPhoneId,
              operation: FilterOperation.In,
              arrayValues: toPhoneIdInTexts
            }
          ]
        : [];

    setTabFilters(FilterQueryVariables2);
  }, [selectedToPhone, setSelectedToPhone]);

  const TabFilter = () => {
    return (
      <ToPhoneTabFilter
        placeholder={"Search a 'To Phone Number'"}
        resource={selectedToPhone}
        setResource={setSelectedToPhone}
      />
    );
  };

  // this is a hook, so we call it here
  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || [],
    (
      toPhone: BaseTextFieldsFragment["toPhone"],
      fromPhone: BaseTextFieldsFragment["fromPhone"],
      message: string
    ) => {
      setShowTestTexts(true);
      setSelectedTextToPhone(toPhone);
      setSelectedTextFromPhone(fromPhone);
      setTextMessage(message);
    },
    "texts"
  );
  const formattedOptOutTexts = useTextTableDataFormatter(
    // @ts-ignore
    optOutTextsData?.optOutTexts?.nodes || [],
    undefined,
    "optOutTexts"
  );

  const [
    ,
    { data: filtersData, loading: loadingFilters, refetch: refetchFilters }
  ] = useGetListFiltersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: ListFilterQueryFilterFields.ProjectId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const handleFiltersRefetch = (options: GetListFiltersListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchFilters(options);
  };

  const [showTestTexts, setShowTestTexts] = useState(true);
  const [selectedTextToPhone, setSelectedTextToPhone] =
    useState<BaseTextFieldsFragment["toPhone"]>();
  const [selectedTextFromPhone, setSelectedTextFromPhone] =
    useState<BaseTextFieldsFragment["fromPhone"]>();
  const [textMessage, setTextMessage] = useState<string>();

  const { data: projectData } = useGetProjectQuery({
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        isView: "true",
        resource: "Project"
      }
    },
    variables: { id }
  });
  const project = useMemo(() => {
    return { ...projectData?.project } as ProjectFieldsFragment;
  }, [projectData]);
  const fromPhones = useMemo(() => {
    const selected = selectedTextFromPhone;
    const projectPhonePhones = project?.projectPhones?.map(p => p.phone);
    const cleanFromPhones = Array.from(new Set(projectPhonePhones));
    const index = cleanFromPhones.findIndex(p => p.id === selected?.id);
    if (index > -1) cleanFromPhones.splice(index, 1);
    return selected ? [selected, ...cleanFromPhones] : cleanFromPhones;
  }, [project, selectedTextFromPhone]);

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const sendText = (phoneTo: string[], body = "", fromPhoneId?: string) => {
    setShowTestTexts(false);
    void sendTextQuery({
      fetchPolicy: "no-cache",
      variables: {
        projectId: id,
        option: LoadSendProjectOption.TestText,
        phoneIds: phoneTo,
        body,
        fromPhoneId
      },
      onCompleted: response => {
        const errors =
          (response?.loadSendProject?.errors as string[] | undefined) || [];
        if (errors?.length > 0) {
          addErrorAlert(alert, "Error, text not sent", errors.join(". "));
        } else {
          alert({
            type: ADD,
            payload: {
              title: "All changes saved",
              message: response.loadSendProject.message,
              variant: "success"
            }
          });
          void refetchTexts;
        }
      },
      onError: err => {
        addErrorAlert(alert, "Error, text not sent", err.message);
        console.error(err.message); // the error if that is the case
      }
    });
  };

  useEffect(() => {
    if (props.activeLinkId === LIST_VIEW.LIST_FILTERS) {
      void refetchFilters();
    } else if (props.activeLinkId === LIST_VIEW.TEAMS) {
      void refetchProjectTeams().then(projectTeams => {
        void refetchTeams().then(() => {
          resourceTabsProviderSetValue(
            projectTabFilters({
              id,
              teamsInProject:
                projectTeams.data.projectTeams?.nodes?.map(
                  pt => pt?.teamId || ""
                ) || [],
              selectedTab: LIST_VIEW.TEAMS
            }).variables as GetTeamsListQueryVariables
          );
        });
      });
    } else if (
      [
        LIST_VIEW.DUPLICATE_PHONES,
        LIST_VIEW.INVALID_PHONES,
        LIST_VIEW.OPT_OUT_PHONES
      ].indexOf(props.activeLinkId as string) !== -1
    ) {
      void refetchListSummary().then(() => {
        resourceTabsProviderSetValue(
          projectTabFilters({
            id,
            selectedTab: props.activeLinkId as string
          }).variables as GetListSummaryQueryVariables
        );
      });
    } else if (
      [LIST_VIEW.TEXTS, LIST_VIEW.TEST_TEXTS, LIST_VIEW.SEED_TEXTS].indexOf(
        props.activeLinkId as string
      ) !== -1
    ) {
      if (
        ["ReceivedOptedOut", "ReceivedNotOptedOut"].indexOf(
          selectedTextType
        ) !== -1
      )
        void refetchOptOutTexts().then(() => {
          const filters = projectTabFilters({
            id,
            selectedTextType,
            selectedTab: props.activeLinkId as string
          }).variables as GetOptOutTextsListQueryVariables;
          resourceTabsProviderSetValue({ ...filters, selectedTextType });
        });
      else
        void refetchTexts().then(() => {
          resourceTabsProviderSetValue(
            projectTabFilters({
              id,
              selectedTextType,
              tabFilters,
              selectedTab: props.activeLinkId as string
            }).variables as GetTextsListQueryVariables
          );
        });
    } else if (props.activeLinkId === LIST_VIEW.TEXTS_INSIGHTS) {
      void refetchTextInsights().then(() => {
        resourceTabsProviderSetValue(
          projectTabFilters({
            id,
            selectedTab: LIST_VIEW.TEXTS_INSIGHTS
          }).variables as TextInsightsQueryVariables
        );
      });
    } else if (props.activeLinkId === LIST_VIEW.TEXTERS) {
      void refetchProjectTexters().then(() => {
        resourceTabsProviderSetValue(
          projectTabFilters({
            id,
            selectedTab: LIST_VIEW.TEXTERS
          }).variables as GetProjectTextersListQueryVariables
        );
      });
    }
  }, [props.activeLinkId, selectedTextType]);

  // reset counter in overview view
  if (
    props.setTotalPages &&
    (props.activeLinkId === LIST_VIEW.OVERVIEW ||
      props.activeLinkId === LIST_VIEW.TEXTS_INSIGHTS)
  )
    props.setTotalPages(null);
  return (
    <>
      {props.activeLinkId === LIST_VIEW.TEXTS && (
        <TextTypeSelect
          selectedTextType={selectedTextType}
          setSelectedTextType={setSelectedTextType}
        />
      )}
      {props.activeLinkId !== LIST_VIEW.OVERVIEW &&
        props.activeLinkId !== LIST_VIEW.TEXTERS &&
        props.activeLinkId !== LIST_VIEW.TEAMS &&
        props.activeLinkId !== LIST_VIEW.INVALID_PHONES &&
        props.activeLinkId !== LIST_VIEW.OPT_OUT_PHONES &&
        props.activeLinkId !== LIST_VIEW.DUPLICATE_PHONES &&
        props.activeLinkId !== LIST_VIEW.LIST_FILTERS &&
        props.activeLinkId !== LIST_VIEW.PRICES &&
        props.activeLinkId !== LIST_VIEW.TEXTS_INSIGHTS && <TabFilter />}
      {props.activeLinkId === LIST_VIEW.TEAMS && (
        <List
          accessor="teams"
          columns={TeamTableColumns}
          data={teamsData}
          dataFormat={teamTableDataFormatter}
          loading={loadingTeams}
          refetchQuery={handleTeamsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTERS && (
        <List
          accessor="projectTexters"
          columns={ProjectTexterTableColumns}
          data={projectTextersData}
          dataFormat={projectTexterDataFormatter}
          loading={loadingProjectTexters}
          refetchQuery={handleProjectTextersRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEST_TEXTS && (
        <>
          <List
            accessor="texts"
            columns={getTextTableColumns({ testTextTable: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
          {showTestTexts && selectedTextToPhone && textMessage && (
            <ProjectTestTexts
              projectName={project.name}
              message={textMessage}
              projectMediaUrl={project.mediaUrl}
              projectMediaPosition={project.mediaPosition}
              managerPhones={project.manager.phones}
              fromPhones={fromPhones}
              clientPhones={project.client.phones}
              alreadySelectedPhone={selectedTextToPhone}
              projectMessage={project.message}
              onSend={sendText}
              onClose={() => {
                setShowTestTexts(false);
              }}
            />
          )}
        </>
      )}
      {props.activeLinkId === LIST_VIEW.SEED_TEXTS && (
        <>
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        </>
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Delivered" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Clicked" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Failed" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Queued" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Expired" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Spam" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "NumberFormat" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "MediaSize" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "ReceivedOptedOut" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...optOutTextsData,
              texts: {
                ...optOutTextsData?.optOutTexts,
                nodes: formattedOptOutTexts
              }
            }}
            loading={loadingOptOutTexts}
            refetchQuery={handleOptOutTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "ReceivedNotOptedOut" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...optOutTextsData,
              texts: {
                ...optOutTextsData?.optOutTexts,
                nodes: formattedOptOutTexts
              }
            }}
            loading={loadingOptOutTexts}
            refetchQuery={handleOptOutTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "RateLimited" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Rejected" && (
          <List
            accessor="texts"
            // get error msg column as well
            columns={getTextTableColumns({ showError: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Late" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Sending" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Sent" && (
        <List
          accessor="texts"
          // get error msg column as well
          columns={getTextTableColumns({ showError: true })}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Active" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Error" && (
        <List
          accessor="texts"
          // get error msg column as well
          columns={getTextTableColumns({ showError: true })}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Ready" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Early" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Undelivered" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "UpdateFailed" && (
          <List
            accessor="texts"
            // get error msg column as well
            columns={getTextTableColumns({ showError: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={handleTextsRefetch}
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "OptOut" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...optOutTextsData,
            texts: {
              ...optOutTextsData?.optOutTexts,
              nodes: formattedOptOutTexts
            }
          }}
          loading={loadingOptOutTexts}
          refetchQuery={handleOptOutTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS_INSIGHTS && (
        <List
          accessor="textInsights"
          columns={TextInsightsTableColumns}
          data={textInsightsData}
          dataFormat={textInsightsDataFormatter}
          loading={loadingTextInsights}
          refetchQuery={handleTextInsightsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.DUPLICATE_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={handleListSummaryRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.INVALID_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={handleListSummaryRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.OPT_OUT_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={handleListSummaryRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.LIST_FILTERS && (
        <>
          <List
            accessor="listFilters"
            columns={FiltersTableColumns}
            data={filtersData}
            dataFormat={data => listFilterTableDataFormatter(data, "Project")}
            loading={loadingFilters}
            refetchQuery={handleFiltersRefetch}
          />
          <div>{message("Project")}</div>
        </>
      )}
      {props.activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
    </>
  );
};

export default ProjectShow;
