import React, { useEffect } from "react";
import {
  Banner,
  Footer,
  FooterText,
  Header,
  ImageWrapper,
  PageWrapper,
  ResponsiveImage,
  StyledH2,
  SubTitleHeader,
  Text,
  TextContainer
} from "./OptInStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientBySlugQuery } from "../../../generated/graphql";
import RGO_LOGO from "../../../assets/opts/logos/readygop_logo.png";
import { defautColor } from "./OptIn";

const OptInTerms = () => {
  const navigateTo = useNavigate();
  const { slug } = useParams();
  const { data } = useGetClientBySlugQuery({
    variables: { slug: slug || "" },
    onCompleted: data => {
      const client = data?.clients?.nodes?.[0];
      if (!client) {
        navigateTo("/");
      }
    }
  });
  const client = data?.clients?.nodes?.[0];

  const mediaUrl = client?.mediaUrl
    ? (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") + client?.mediaUrl
    : "";

  const clientName = client?.name;
  const organizationName = client?.name;
  const organizationWebSite = client?.website;
  const organizationEmail = client?.email;
  const clientState = client?.state;
  const color = client?.color || defautColor;
  const slogan = client?.slogan;

  useEffect(() => {
    const tabTitle = (clientName || "") + " - Terms";
    let link: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = mediaUrl || RGO_LOGO;
    document.title = tabTitle;
  }, [clientName, mediaUrl]);

  return (
    <PageWrapper>
      <Header color={color}>Terms and Conditions</Header>
      <Banner>
        <ImageWrapper>
          <ResponsiveImage src={mediaUrl || ""} />
        </ImageWrapper>
        <SubTitleHeader color={color}>{slogan}</SubTitleHeader>
      </Banner>
      <TextContainer>
        <Text>Last updated: Feb 24, 2025</Text>
        <Text>
          <b>
            Welcome to {organizationName}. By using our website and services,
            you agree to comply with and be bound by the following terms and
            conditions.
          </b>
        </Text>
        <StyledH2 color={color}>1. Introduction</StyledH2>
        <Text>
          {organizationWebSite} provides advocacy and information services. By
          accessing and using our website, you accept these terms and conditions
          in full. If you disagree with any part of these terms and conditions,
          you must not use our website.
        </Text>
        <StyledH2 color={color}>2. Eligibility</StyledH2>
        <Text>
          You must be at least 18 years of age to use our services. By using our
          website and agreeing to these terms and conditions, you warrant and
          represent that you are at least 18 years of age.
        </Text>
        <StyledH2 color={color}>3. 10DLC Compliance</StyledH2>
        <Text>
          <p>A. Consent</p>
          <p>
            By providing your mobile phone number, you consent to receive
            SMS/MMS messages from {organizationWebSite}. Consent to receive
            marketing text messages is not required as a condition of purchasing
            any goods or services.
          </p>
          <p>B. Message Frequency</p>
          <p>
            Message frequency varies, typically up to 3 messages per week.
            Standard message and data rates may apply.
          </p>
          <p>C. Opt-Out</p>
          <p>
            You may opt out of receiving text messages at any time by texting
            STOP. You will receive a one-time opt-out confirmation text message.
            If you need assistance, text HELP.
          </p>
          <p>D. Data Privacy</p>
          <p>
            We will not share or sell your personal information, including your
            phone number, to any third parties except as required by law. For
            more details, please see our Privacy Policy.
          </p>
        </Text>
        <StyledH2 color={color}>4. Use of the Website</StyledH2>
        <Text>
          You agree to use the website for lawful purposes only and not to
          engage in any activity that disrupts or interferes with the website or
          its services.
        </Text>
        <StyledH2 color={color}>5. Intellectual Property</StyledH2>
        <Text>
          All content, trademarks, and data on this website, including but not
          limited to text, graphics, logos, icons, images, audio clips, digital
          downloads, data compilations, and software, are the property of
          {organizationWebSite} or its content suppliers and are protected by
          international copyright laws.
        </Text>
        <StyledH2 color={color}>6. Use Restrictions and Termination</StyledH2>
        <Text>
          {organizationWebSite} will not be liable for any direct, indirect,
          incidental, consequential, or punitive damages arising out of your
          access to, or use of, the website.
        </Text>
        <StyledH2 color={color}> 7. Disclaimer of Warranties </StyledH2>
        <Text>
          The information provided on this website is provided &quot;as is&quot;
          and &quot;as available&quot; without any warranties of any kind,
          either express or implied.
        </Text>
        <StyledH2 color={color}>8. Changes to Terms and Conditions</StyledH2>
        <Text>
          We reserve the right to modify these terms and conditions at any time.
          Any changes will be effective immediately upon posting on the website.
          Your continued use of the website will signify your acceptance of the
          new terms.
        </Text>
        <StyledH2 color={color}>9. Governing Law</StyledH2>
        <Text>
          These terms and conditions are governed by and construed in accordance
          with the laws of {clientState}, and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </Text>
        <StyledH2 color={color}>10. Contact Information</StyledH2>
        <Text>
          For any questions about these terms and conditions, please contact us
          at: <a href={""}>{organizationEmail}</a>
        </Text>
      </TextContainer>
      <Footer color={color}>
        <FooterText>© 2025 All rights reserved.</FooterText>
        <FooterText>{client?.email}</FooterText>
      </Footer>
      ;
    </PageWrapper>
  );
};

export default OptInTerms;
