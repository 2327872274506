import { badgeCell } from "@onehq/anton";
import { ProjectStatus } from "../generated/graphql";
import { addSpacesBetweenWords } from "./helperFunctions";
import styled, { keyframes } from "styled-components";
import React from "react";

const blinkGreen = keyframes`
    0%, 100% { background-color: #28a745; }
    50% { background-color: #c8ffd7; }
`;

export const ProjectStatusSendingBadge = styled.div`
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #246b2d;
  background-color: #28a745;
  animation: ${blinkGreen} 2s infinite ease-in-out;
`;

export const ProjectStatusErrorBadge = styled.div`
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  background-color: #d00000;
`;

export function projectStatusGroup(status: ProjectStatus) {
  switch (status) {
    case ProjectStatus.Setup:
    case ProjectStatus.Submitted:
    case ProjectStatus.Assigned:
      return "Draft";
    case ProjectStatus.Approved:
      return "Approved";
    case ProjectStatus.AwaitingLoad:
    case ProjectStatus.Loading:
    case ProjectStatus.LoadingWork:
      return "Loading";
    case ProjectStatus.AwaitingUnload:
    case ProjectStatus.Unloading:
      return "Unloading";
    case ProjectStatus.Error:
    case ProjectStatus.ErrorLoading:
    case ProjectStatus.ErrorLoadingWork:
      return "Error";
    default:
      return addSpacesBetweenWords(status);
  }
}

export function projectStatusBadge(status: ProjectStatus) {
  const finalStatus = projectStatusGroup(status);
  switch (finalStatus) {
    case "Draft":
      return badgeCell({ value: finalStatus, color: "default" });
    case "Approved":
      return badgeCell({ value: finalStatus, color: "purple" });
    case "Loading":
      return badgeCell({ value: finalStatus, color: "yellow" });
    case "Queued":
      return badgeCell({ value: finalStatus, color: "sky" });
    case "Ready":
    case "Live":
      return badgeCell({ value: finalStatus, color: "green" });
    case "Sending":
      return (
        <div style={{ textAlign: "center" }}>
          <ProjectStatusSendingBadge>{finalStatus}</ProjectStatusSendingBadge>
        </div>
      );
    case "Complete":
      return badgeCell({ value: finalStatus, color: "blue" });
    case "Cancelled":
      return badgeCell({ value: finalStatus, color: "red" });
    case "Error":
      return (
        <div style={{ textAlign: "center" }}>
          <ProjectStatusErrorBadge>{finalStatus}</ProjectStatusErrorBadge>
        </div>
      );
    default:
      return badgeCell({ value: finalStatus, color: "redBackground" });
  }
}
