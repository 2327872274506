import React, { useCallback, useMemo, useState } from "react";
import { formatDollar } from "@onehq/helpers";
import {
  CollectionListFieldsFragment as Collection,
  CollectionQueryFilterFields,
  FilterOperation,
  useBillingReportQuery,
  useCollectionsSummaryQuery,
  useGetCollectionsListQuery
} from "../../generated/graphql";
import BalanceTable from "./balanceReportTable";
import { Fieldset, TextInput } from "@onehq/anton";
import CollectionTable from "../Collection/CollectionTable";
import DatesRangeForm, {
  DatesRangeFormSubmit as Submit
} from "../../pages/auditReport/datesRangeForm";

interface BalanceProps {
  id: string;
}

const Balance = ({ id }: BalanceProps) => {
  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const [startDate, setStartDate] = useState(startOfDay(today));
  const [endDate, setEndDate] = useState(endOfDay(today));

  // costs
  const { data: billingData, loading: loadingBilling } = useBillingReportQuery({
    fetchPolicy: "cache-and-network",
    variables: { startDate, endDate }
  });
  const costs = useMemo(() => {
    const nodes = billingData?.billingReport.nodes || [];

    const loadCosts = (nodes: any[], type: string, subKey: string) => {
      const key = `total${type}${subKey}`;
      return nodes.reduce((t, v) => t + parseFloat(v[key] as string), 0.0);
    };

    const costs: Record<string, object> = {
      Registered: {
        SentSms: 0,
        SentMms: 0,
        ReceivedSms: 0,
        ReceivedMms: 0
      },
      Unregistered: {
        SentSms: 0,
        SentMms: 0,
        ReceivedSms: 0,
        ReceivedMms: 0
      }
    };

    Object.keys(costs).forEach(key => {
      const rows = nodes.filter(n => compareTypes(key, n?.campaignTypeId));
      Object.keys(costs[key]).forEach(subKey => {
        costs[key][subKey] = loadCosts(rows, key, subKey) as number;
      });
    });

    return costs;
  }, [billingData]);
  const totalCost = useMemo(() => {
    const types = Object.values(costs);
    const total: number = types.reduce((total, type) => {
      return total + Object.values(type).reduce((sum, val) => sum + val, 0.0);
    }, 0.0);
    return total;
  }, [costs]);

  // collections
  const { data: collectionData, loading: loadingCollestions } =
    useGetCollectionsListQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        filters: [
          {
            field: CollectionQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          },
          {
            field: CollectionQueryFilterFields.CollectionDate,
            operation: FilterOperation.GreaterThan,
            value: startDate
          },
          {
            field: CollectionQueryFilterFields.CollectionDate,
            operation: FilterOperation.LessThan,
            value: endDate
          }
        ]
      }
    });
  const collections = useMemo(() => {
    return (collectionData?.collections.nodes || []) as Collection[];
  }, [collectionData]);

  // collections summary
  const { data: summaryData, loading: loadingSummary } =
    useCollectionsSummaryQuery({
      fetchPolicy: "cache-and-network",
      variables: { startDate, endDate }
    });
  const summary = useMemo(() => {
    const summary = summaryData?.collectionsSummary || {};
    if (!summary) return undefined;
    return {
      collectionType: "Total",
      amount: formatDollar(summary.total_amount as string, 2),
      fee: formatDollar(summary.total_fee as string, 2)
    };
  }, [summaryData]);
  const income = useMemo(() => {
    const amount = summaryData?.collectionsSummary.total_amount as string;
    return parseFloat(amount) || 0;
  }, [summaryData]);

  const onSubmit: Submit = useCallback(({ startDate, endDate }) => {
    setStartDate(startOfDay(startDate));
    setEndDate(endOfDay(endDate));
    return Promise.resolve();
  }, []);

  return (
    <>
      <DatesRangeForm defaultDate={today} onSubmit={onSubmit} />

      <Fieldset legend="Registered Campaigns" />
      <BalanceTable data={[costs.Registered]} loading={loadingBilling} />

      <Fieldset legend="Unregistered Campaigns" />
      <BalanceTable data={[costs.Unregistered]} loading={loadingBilling} />

      <Fieldset legend="Total Campaigns" />
      <TextInput
        value={loadingBilling ? "--" : formatDollar(totalCost)}
        disabled
      />

      <Fieldset legend="Payments" />
      <CollectionTable
        data={collections}
        loading={loadingCollestions || loadingSummary}
        summary={summary}
      />

      <Fieldset legend="Total Payments" />
      <TextInput
        value={loadingSummary ? "--" : formatDollar(income)}
        disabled
      />

      <Fieldset legend="Results" />
      <TextInput
        value={
          loadingSummary || loadingBilling
            ? "--"
            : formatDollar(totalCost - income)
        }
        disabled
      />
    </>
  );
};

const startOfDay = (date: string) => {
  return `${date}T00:00:00`;
};

const endOfDay = (date: string) => {
  return `${date}T23:59:59`;
};

const compareTypes = (value: string, optionId?: string | null) => {
  if (!optionId) return false;
  const type = optionId?.split("::::")[1];
  return type === value;
};

export default Balance;
