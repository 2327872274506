import { FormSection } from "@onehq/framework";

import OrganizationGeneralForm from "./OrganizationGeneralForm";
import TextDefaultForm from "./TextDefaultForm";
import CustomDomainsForm from "./CustomDomainsForm";
import ListFiltersForm from "../../../../components/ListFilter/ListFiltersForm";
import ProviderColorsForm from "./ProviderColorsForm";
import PriceSection from "../../../../components/Price/PriceSection";

export default [
  new FormSection("General", OrganizationGeneralForm),
  new FormSection("Text Defaults", TextDefaultForm),
  new FormSection("Domains", CustomDomainsForm),
  new FormSection("List Filters", ListFiltersForm),
  new FormSection("Providers Colors", ProviderColorsForm),
  new FormSection("Prices", PriceSection)
];
