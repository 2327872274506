/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ProjectListFieldsFragment } from "../../generated/graphql";
import { CellDataType, NoWrapCell, Table } from "@onehq/anton";
import {
  addSpacesBetweenWords,
  formatDate,
  formatPhone,
  projectStatusBadge
} from "../../utils";
import { Link } from "@onehq/framework";
import { BaseTableProps } from "../../types";
import { CLIENTS_PATH, PROJECTS_PATH } from "../../constants";

export type ProjectTableProps = BaseTableProps<ProjectListFieldsFragment>;

export const ProjectTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name"
  },
  {
    id: "message",
    header: "Message",
    accessorKey: "message",
    dataType: "longText" as CellDataType,
    cell: NoWrapCell,
    columnMaxWidth: 200,
    tooltipMaxLength: 60
  },
  {
    id: "startAt",
    header: "Start At",
    accessorKey: "startAt"
  },
  {
    id: "projectStatusId",
    header: "Status",
    accessorKey: "projectStatusId"
  },
  {
    id: "listCount",
    header: "List Count",
    accessorKey: "listCount",
    type: "number"
  },
  {
    id: "client",
    header: "Client",
    accessorKey: "client",
    enableSorting: false
  },
  {
    id: "projectTypeId",
    header: "Type",
    accessorKey: "projectTypeId"
  },
  {
    id: "projectPhones",
    header: "Phones",
    accessorKey: "projectPhones",
    enableSorting: false,
    cell: NoWrapCell,
    columnMaxWidth: 20,
    tooltipMaxLength: 10
  }
];

export const projectTableDataFormatter = (
  data?: Array<ProjectListFieldsFragment>
) => [
  ...(data?.map((item: ProjectListFieldsFragment) => {
    const { client, listCount, message, phones, name, status, type, startAt } =
      item;

    return {
      client: (
        <Link to={`/${CLIENTS_PATH}/${client.id}/overview`}>{client.name}</Link>
      ),
      listCount,
      name: <Link to={`/${PROJECTS_PATH}/${item.id}/overview`}>{name}</Link>,
      message,
      projectStatusId: projectStatusBadge(status),
      projectPhones: phones
        ?.map(el => formatPhone(el.number.toString()))
        .join(", "),
      projectTypeId: addSpacesBetweenWords(type),
      startAt: formatDate(startAt)
    };
  }) || [])
];

const ProjectTable = ({ data = [], ...props }: ProjectTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ProjectTableColumns}
      data={projectTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ProjectTable;
