/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { formatDollar } from "@onehq/helpers";
import { createColumnHelper } from "@tanstack/react-table";
import { Table, TableProps } from "@onehq/anton";
import { BaseTableProps } from "../../types";
import {
  BillingReport as Billing,
  CampaignType
} from "../../generated/graphql";
import { parseInt, round } from "lodash";

export interface BalanceRow {
  SentSms: number;
  SentMms: number;
  ReceivedSms: number;
  ReceivedMms: number;
}
type BalanceTableProps = BaseTableProps<BalanceRow | {}>;
type Columns = TableProps["columns"];

const defaultValues: BalanceRow = {
  SentSms: 0,
  SentMms: 0,
  ReceivedSms: 0,
  ReceivedMms: 0
};

const helper = createColumnHelper<any>();
export const balanceTableColumns: Columns = [
  helper.group({
    id: "sent",
    header: "Sent",
    columns: [
      helper.accessor("SentSms", { id: "SentSms", header: "SMS" }),
      helper.accessor("SentMms", { id: "SentMms", header: "MMS" })
    ]
  }),
  helper.group({
    id: "received",
    header: "Received",
    columns: [
      helper.accessor("ReceivedSms", {
        id: "ReceivedSms",
        header: "SMS"
      }),
      helper.accessor("ReceivedMms", {
        id: "ReceivedMms",
        header: "MMS"
      })
    ]
  })
];

export const balanceTotals = (
  data: Array<Billing | null> = [],
  type: CampaignType = CampaignType.Registered,
  organizationId?: string
): BalanceRow => {
  const typeId = `CampaignType::::${type}`;
  const rows = data.filter(r => {
    return r?.campaignTypeId === typeId && r?.organizationId === organizationId;
  });

  const initialValues = { ...defaultValues };
  const balance = rows.reduce((totals, row) => {
    if (row?.textType === "SMS") {
      totals.ReceivedSms += parseInt(row?.received || "");
      totals.SentSms += parseInt(row?.totalSent || "");
    } else {
      totals.ReceivedMms += parseInt(row?.received || "");
      totals.SentMms += parseInt(row?.totalSent || "");
    }
    return totals;
  }, initialValues);

  return balance;
};

export const prices = (type: CampaignType) => {
  const price: BalanceRow = {
    SentSms: round(type === CampaignType.Registered ? 0.01 : 0.02, 3),
    SentMms: round(type === CampaignType.Registered ? 0.035 : 0.036, 3),
    ReceivedSms: round(type === CampaignType.Registered ? 0.01 : 0.02, 3),
    ReceivedMms: round(type === CampaignType.Registered ? 0.035 : 0.036, 3)
  };
  return price;
};

export const totalCosts = (balance: BalanceRow, price: BalanceRow) => {
  const costs = {
    SentSms: round(balance.SentSms * price.SentSms, 2),
    SentMms: round(balance.SentMms * price.SentMms, 2),
    ReceivedSms: round(balance.ReceivedSms * price.ReceivedSms, 2),
    ReceivedMms: round(balance.ReceivedMms * price.ReceivedMms, 2)
  };
  return costs;
};

// always 4 rows:
// 1. total messages sent/received
// 2. blank row
// 3. price per message sent/received
// 4. final price for every type of messages
export const balanceTableDataFormatter = (
  data: Array<Billing | null> = [],
  type: CampaignType = CampaignType.Registered,
  organizationId?: string
): (BalanceRow | {})[] => {
  const balance = balanceTotals(data, type, organizationId);
  const price = prices(type);
  const totals = totalCosts(balance, price);

  return [balance, {}, price, totals];
};

const formatNumberRows = (rows: (BalanceRow | {})[]) => {
  return rows.map((row, index) => {
    const final = Object.assign({}, row);
    const keys = Object.keys(final);
    if (keys.length === 0) return final;
    keys.forEach(key => {
      const value = final[key] as number;
      if (index === 0) {
        final[key] = formatDollar(value, 2);
      } else if (index === 2) {
        final[key] = formatDollar(value, 3);
      } else {
        final[key] = formatDollar(value, 2);
      }
    });
    return final;
  });
};

const BalanceTable = ({ data = [], ...props }: BalanceTableProps) => {
  return (
    <Table
      columns={balanceTableColumns}
      data={formatNumberRows(data)}
      skeleton={props.loading}
      externalSort={props.externalSort}
    />
  );
};

export default BalanceTable;
