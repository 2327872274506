import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  Table,
  Fieldset,
  HiddenField,
  NestedForm,
  TableProps,
  TextField
} from "@onehq/anton";
import {
  CampaignType,
  MessagingServiceType,
  PriceFieldsFragment
} from "../../generated/graphql";

type Price = PriceFieldsFragment;
type Columns = TableProps["columns"];
const helper = createColumnHelper<any>();

const names = [
  "SMS-Registered-sent",
  "SMS-Registered-received",
  "SMS-Unregistered-sent",
  "SMS-Unregistered-received",
  "MMS-Registered-sent",
  "MMS-Registered-received",
  "MMS-Unregistered-sent",
  "MMS-Unregistered-received"
];

const Fields = () => {
  return (
    <>
      <TextField name="price" label="" span={24} isTableInput />
      <HiddenField name="id" />
      <HiddenField name="messagingServiceType" />
      <HiddenField name="campaignType" />
      <HiddenField name="isSent" />
      <HiddenField name="organizationId" />
    </>
  );
};

export const PriceField = ({ name }: { name: string }) => {
  return <NestedForm name={name} component={Fields} hasOne />;
};

export const priceTableColumns = (type: CampaignType): Columns => {
  return [
    helper.group({
      id: "sent",
      header: "Sent",
      columns: [
        helper.accessor("sms-sent", {
          id: "sms-sent",
          header: "SMS",
          cell: () => <PriceField name={`SMS-${type}-sent`} />
        }),
        helper.accessor("mms-sent", {
          id: "mms-sent",
          header: "MMS",
          cell: () => <PriceField name={`MMS-${type}-sent`} />
        })
      ]
    }),
    helper.group({
      id: "received",
      header: "Received",
      columns: [
        helper.accessor("sms-received", {
          id: "sms-received",
          header: "SMS",
          cell: () => <PriceField name={`SMS-${type}-received`} />
        }),
        helper.accessor("mms-received", {
          id: "mms-received",
          header: "MMS",
          cell: () => <PriceField name={`MMS-${type}-received`} />
        })
      ]
    })
  ];
};

export const PriceNestedFields = () => {
  const Fields = useMemo(() => {
    const registeredColumns = priceTableColumns(CampaignType.Registered);
    const unregisteredColumns = priceTableColumns(CampaignType.Unregistered);
    return (
      <Fieldset>
        <Fieldset legend="Registered" />
        <Table columns={registeredColumns} data={[{ id: "" }]} />
        <Fieldset legend="Unregistered" />
        <Table columns={unregisteredColumns} data={[{ id: "" }]} />
      </Fieldset>
    );
  }, []);

  return <Fieldset>{Fields}</Fieldset>;
};

const PriceSection = () => {
  return <NestedForm name="prices" component={PriceNestedFields} hasOne />;
};

export const loadPricesInitialValues = (prices: Price[]) => {
  return prices.reduce((prices, price) => {
    const messageType = price.messagingServiceType;
    const campaignType = price.campaignType;
    const isSent = price.isSent ? "sent" : "received";
    const name = `${messageType}-${campaignType}-${isSent}`;
    return { ...prices, [name]: price };
  }, {});
};

export const loadPricesNormalizedValues = (values: any) => {
  const prices = names.map(name => {
    const [messagingServiceType, campaignType, sentType] = name.split("-");
    if (!values[name]) return undefined;

    const { id, price, organizationId } = values[name];
    return {
      id,
      messagingServiceType: messagingServiceType as MessagingServiceType,
      campaignType: campaignType as CampaignType,
      isSent: sentType === "sent" || null,
      price: (price && parseFloat(price as string)) || null,
      organizationId,
      _destroy: id && !price
    };
  });
  return prices.filter(price => price && (price.price || price._destroy));
};

export default PriceSection;
