import React, { useMemo, useState } from "react";

import _ from "lodash";
import {
  Form,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";

import {
  ProviderColorInput,
  ListFilterFieldsFragment,
  TextDefaultInput,
  UpdateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation
} from "../../../generated/graphql";
import {} from "../../../types";
import { addAlert, removeAlert } from "../../../utils";
import sections from "./sections";
import {
  formatFilters,
  ListFilterOptions,
  ListFilterValue,
  normalizeFilters
} from "../../../components/ListFilter/utils";
import {
  loadPricesInitialValues,
  loadPricesNormalizedValues
} from "../../../components/Price/PriceSection";

export interface OrganizationEditProps {
  id: string;
}

const OrganizationEdit = ({ id }: OrganizationEditProps) => {
  const organizationAlert: GrowlAlertDispatch = useDispatchGrowlContext();
  const [alertID] = useState(Math.floor(Math.random() * 10000 + 1));

  const { data, loading } = useGetOrganizationQuery({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });

  const [updateOrganization] = useUpdateOrganizationMutation({
    onCompleted: (response: UpdateOrganizationMutation) => {
      if (Object.keys(response.updateOrganization?.errors || {}).length === 0) {
        addAlert(organizationAlert, "organization.changed");
      } else {
        console.error(response.updateOrganization?.errors); // the error if that is the case
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  const initialListFilters = useMemo(() => {
    const filters = data?.organization?.listFilters || [];
    return filters.filter(l => !l.clientId && !l.projectId);
  }, [data]);

  const listFiltersAttributes = useMemo(() => {
    const filters = formatFilters(initialListFilters);
    return [filters.find(f => f.type === "Other") as ListFilterOptions];
  }, [initialListFilters]);

  const organizationValues = data?.organization;

  const values = {
    ...organizationValues,
    textDefaults: organizationValues?.textDefaults.map(
      ({ field, id, value }: TextDefaultInput) => ({
        id,
        field,
        valueField: value
      })
    ),
    customDomainsAttributes:
      organizationValues?.customDomains.map(({ id, domain }): any => {
        return { id, domain };
      }) || [],
    listFilters: initialListFilters,
    providerColors: organizationValues?.providerColors,
    listFiltersAttributes,
    prices: loadPricesInitialValues(
      organizationValues?.prices.filter(p => !p.projectId && !p.clientId) || []
    )
  };

  return (
    <Form
      autosave
      // @ts-ignore
      sections={sections}
      skeleton={loading || !organizationValues}
      values={values}
      withSelectedSection
      // eslint-disable-next-line @typescript-eslint/require-await
      onSubmit={async (attr: any) => {
        addAlert(organizationAlert, "organization.save", alertID);

        const newAttr = _.omit(
          attr,
          "__typename",
          "timeZone",
          "customDomainsAttributes",
          "logotypeUrl",
          "listFilters",
          "listFiltersAttributes",
          "collectionType",
          "amount",
          "fee",
          "collectionDate"
        );

        newAttr.textDefaults = attr.textDefaults
          ?.filter(td => td.id || (td.field && td.valueField))
          .map((td: TextDefaultInput & { valueField: string }) => {
            const { id, field, valueField, X } = td;

            return {
              id: id || null,
              field,
              value: valueField,
              _destroy: !!(id && X)
            };
          });

        newAttr.customDomains = attr.customDomainsAttributes.map(
          ({ id, domain, X }): any => {
            return { id, domain, _destroy: !!(id && X) };
          }
        );

        const listFilters: ListFilterValue[] = attr.listFiltersAttributes;
        const previous: ListFilterFieldsFragment[] = attr.listFilters;
        newAttr.listFilters = normalizeFilters(listFilters, previous);

        newAttr.providerColors = attr.providerColors.map(
          (pc: ProviderColorInput) => {
            const { id, provider, color, X } = pc;

            return {
              id: id || null,
              provider,
              color,
              _destroy: !!(id && X)
            };
          }
        );

        newAttr.prices = loadPricesNormalizedValues(attr.prices);

        if (attr.collectionType) {
          newAttr.collections = [
            {
              collectionType: attr.collectionType,
              amount: attr.amount,
              fee: attr.fee,
              collectionDate: attr.collectionDate
            }
          ];
        }

        await updateOrganization({
          variables: { id: newAttr.id, attributes: newAttr }
        }).then(() => {
          removeAlert(organizationAlert, alertID);
        });
      }}
    />
  );
};

export default OrganizationEdit;
